import { WorkflowExhibit, WorkflowExhibitType, WorkflowModule } from "@codegen/enums";
import { ExhibitStatusType, IndividualDocumentType, VisaClassType } from "@codegen/schema";
import { CreatorExhibit } from "@components/creator/types";

export type PlymouthUser = {
  id: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
  usAddress?: Address;
  foreignAddress?: Address;
  teams: TeamGroup[];
  portalOnboarded?: boolean;
  role?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
};
export type TeamType = "team" | "personal";
export type Team = {
  label: string;
  value: string;
  type: TeamType;
  portalOnboarded?: boolean | null;
  hqAddress?: Address;
};

export type TeamGroup = {
  label: string;
  teams: Team[];
};

export const criteriaList: {
  title: string;
  key: string;
  description: string;
}[] = [
  {
    title: "Critical Role",
    key: "critical-role",
    description: "You perform a crucial role at a respected company or startup",
  },
  {
    title: "High Remuneration",
    key: "high-remuneration",
    description: "You receive a large salary or other substantial compensation",
  },
  {
    title: "Membership",
    key: "membership",
    description:
      "You belong to a group that requires great achievements, as judged by experts",
  },
  {
    title: "Judging",
    key: "judging",
    description:
      "You have judged the work of your peers individually or on a panel",
  },
  {
    title: "Awards",
    key: "awards",
    description:
      "You have won national or global awards for excellence in your field",
  },
  {
    title: "Authorship",
    key: "authorship",
    description:
      "You have written articles for important publications in your field",
  },
  {
    title: "Press",
    key: "press",
    description:
      "Respected publications or major media outlets have published articles about you",
  },
  {
    title: "Original Contribution",
    key: "original-contribution",
    description: "You have made important, new contributions to your field",
  },
  {
    title: "Employer Documents",
    key: "employer-documents",
    description: "",
  },
  {
    title: "Immigration History",
    key: "immigration-history",
    description: "",
  },
];
export type Address = {
  street?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
  country?: string | null;
  isWorksite?: boolean | null;
};

export type FormStatus = "idle" | "uploading" | "success" | "error";

export enum ModuleStatus {
  Pending = 'pending',
  Completed = 'completed',
  InProgress = 'in_progress'
}

export type OnboardingStrategyExhibitBase = Omit<CreatorExhibit, 'description' | 'data' | 'visible'> & {
  id: string
  status: ExhibitStatusType
  required: boolean
  type: WorkflowExhibitType
  description?: React.ReactNode
  data: any
}
export type OnboardingStrategyExhibitCodeContribution = OnboardingStrategyExhibitBase & {
  type: 'CODE_CONTRIBUTION',
  data: {
    files: { fileType: IndividualDocumentType }[]
  }
}

export type StrategyExhibit = WorkflowExhibit

export type StrategyModule = WorkflowModule

export type PetitionCreatorPayload = {
  beneficiaryId?: string,
  petitionerId?: string,
  visaClass: VisaClassType,
  modules: StrategyModule[],
}