import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { EmployerOnboardingContainer } from './employer-onboarding-container';
import { useEmployerOnboarding } from '../../employer-onboarding';
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@components/form';
import { Input } from '@components/input';
import { Button } from '@components/button';
import { FieldsContainer } from '@pages/onboarding/shared/fields-container';
import { CTAContainer } from '@pages/onboarding/shared/cta-container';
import * as Select from "@radix-ui/react-select";

import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { cn } from '@utils/cn';
import { Checkbox } from '@components/checkbox';
import { PetitionFragment, UserEmploymentInput, UpdateUserEmploymentByIdInput, UserEmploymentsQuery } from '@codegen/schema';
import { useCreateUserEmploymentMutation, useUpdateUserEmploymentByIdMutation } from '@codegen/index';
import { useLogError } from '@utils/error';

const usRoleOfferSchema = z.object({
  roleTitle: z.string().min(1, 'Required'),
  proposedSalary: z.number().min(1, 'Required'),
  salaryFrequency: z.enum(['annual', 'monthly', 'weekly', 'hourly']),
  otherCompensation: z.object({
    equityAndStandardBenefits: z.boolean(),
    otherGuaranteedPay: z.boolean(),
    other: z.string(),
  }),
  startDate: z.string().min(1, 'Required'),
  isFullTime: z.enum(['yes', 'no']),
  hoursPerWeek: z.number().optional(),
});

type USRoleOfferType = z.infer<typeof usRoleOfferSchema>

const SelectItem = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<typeof Select.Item> & { className?: string }
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <Select.Item
      className={cn(
        "relative flex h-[25px] select-none items-center rounded-[3px] pl-[25px] pr-[35px] text-[13px] leading-none text-violet11 data-[disabled]:pointer-events-none data-[highlighted]:bg-accent data-[disabled]:text-mauve8 data-[highlighted]:text-white data-[highlighted]:outline-none",
        className,
      )}
      {...props}
      ref={forwardedRef}
    >
      <Select.ItemText>{children}</Select.ItemText>
      <Select.ItemIndicator className="absolute left-0 inline-flex w-[25px] items-center justify-center">
        <CheckIcon />
      </Select.ItemIndicator>
    </Select.Item>
  );
});


type UserEmploymentFragment = NonNullable<NonNullable<UserEmploymentsQuery['allUserEmployments']>['nodes']>[number];
type USRoleOfferProps = {
  petition: PetitionFragment;
  existingEmployment?: UserEmploymentFragment;
  hoursPerWeek?: number;
  onSubmit: (data: any) => void;
  onBack: () => void;
}

export const USRoleOfferForm: React.FC<USRoleOfferProps> = ({ petition, existingEmployment, onSubmit, onBack }) => {
  const { company } = useEmployerOnboarding();

  const form = useForm<USRoleOfferType>({
    resolver: zodResolver(usRoleOfferSchema),
    defaultValues: {
      roleTitle: existingEmployment?.position || '',
      proposedSalary: existingEmployment?.salary || 0,
      salaryFrequency: (existingEmployment?.salaryFrequency as any) || 'annual',
      otherCompensation: existingEmployment?.otherCompensation
        ? JSON.parse(existingEmployment.otherCompensation) 
        : {
            equityAndStandardBenefits: false,
            otherGuaranteedPay: false,
            other: '',
          },
      startDate: existingEmployment?.startDate || '',
      isFullTime: existingEmployment?.isFullTime ? 'yes' : 'no',
      hoursPerWeek: existingEmployment?.hoursPerWeek || 40,
    },
  });

  const [createUserEmployment] = useCreateUserEmploymentMutation();
  const [updateUserEmployment] = useUpdateUserEmploymentByIdMutation();
  const logError = useLogError();

  const handleSubmit = async (data: z.infer<typeof usRoleOfferSchema>) => {
    try {
      const userEmploymentInput = {
        companyName: company.label,
        position: data.roleTitle,
        startDate: data.startDate,
        endDate: null,
        salary: data.proposedSalary,
        salaryFrequency: data.salaryFrequency,
        otherCompensation: JSON.stringify(data.otherCompensation),
        isFullTime: data.isFullTime === 'yes',
        hoursPerWeek: data.hoursPerWeek,
      };

      // If we have an existing employment record, update it
      if (existingEmployment) {
        const result = await updateUserEmployment({
          variables: {
            input: {
              clientMutationId: '',
              id: existingEmployment.id,
              // @ts-ignore
              userEmploymentPatch: userEmploymentInput
            }
          }
        });

        if (result.errors) {
          throw result.errors[0];
        }
      } else {
        if (!petition.userByBeneficiaryId?.id) {
          throw new Error('User ID is required');
        }

        // Otherwise create a new one
        const result = await createUserEmployment({
          variables: {
            input: {
              clientMutationId: '',
              // @ts-ignore
              userEmployment: {
                ...userEmploymentInput,
                companyId: company.value,
                userId: petition.userByBeneficiaryId.id,
                isCurrent: false,
              }
            }
          }
        });

        if (result.errors) {
          throw result.errors[0];
        }
      }

      // Call parent onSubmit with form data
      onSubmit(data);
    } catch (error) {
      logError(error);
      // Set form error
      form.setError('root.serverError', {
        // @ts-ignore
        type: error.name, message: error.message
      });
    }
  };

  const otherCompensation = form.watch('otherCompensation.other')
  return (
    <EmployerOnboardingContainer
      title="US Role Offer"
      subtitle={<>Please provide details about the role offer for <b>{petition.userByBeneficiaryId?.fullName}</b></>}
      form={form}
      onSubmit={handleSubmit}
      name="us_role_offer"
      progress={0}
    >
      <FieldsContainer status="idle" className="space-y-2">
        <div className="grid grid-cols-2 gap-4">
          <FormField
            key={`roleTitle-${existingEmployment?.id}`}
            control={form.control}
            name="roleTitle"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Role Title</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            key={`startDate-${existingEmployment?.id}`}
            control={form.control}
            name="startDate"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Start Date</FormLabel>
                <FormControl>
                  <Input type="date" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <h3>Compensation Details</h3>
        <div className="grid grid-cols-2 gap-4">
          <FormField
            key={`proposedSalary-${existingEmployment?.id}`}
            control={form.control}
            name="proposedSalary"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Proposed U.S. Salary</FormLabel>
                <FormControl>
                  <Input type="number" {...field} onChange={e => field.onChange(parseFloat(e.target.value))} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="salaryFrequency"
            render={({ field }) => (
              <FormItem className="space-y-2">
                <FormLabel>Salary Frequency</FormLabel>
                <FormControl>
                  <Select.Root onValueChange={field.onChange} defaultValue={field.value}>
                    <Select.Trigger
                      className="flex h-10 w-full rounded-sm border border-input px-3 p-2 bg-white text-sm shadow-black/10 outline-none hover:bg-mauve3 focus:shadow-[0_0_0_1px] focus:shadow-accent data-[placeholder]:text-accent shadow-sm disabled:cursor-not-allowed disabled:opacity-50"
                      aria-label="Salary Frequency"
                    >
                      <Select.Value />
                    </Select.Trigger>
                    <Select.Portal>
                      <Select.Content className="overflow-hidden rounded-md bg-white shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]">
                        <Select.ScrollUpButton className="flex h-[25px] cursor-default items-center justify-center bg-white text-violet11">
                          <ChevronUpIcon />
                        </Select.ScrollUpButton>
                        <Select.Viewport>
                          <SelectItem value="annual">Annual</SelectItem>
                          <SelectItem value="monthly">Monthly</SelectItem>
                          <SelectItem value="weekly">Weekly</SelectItem>
                          <SelectItem value="hourly">Hourly</SelectItem>
                        </Select.Viewport>
                        <Select.ScrollDownButton className="flex h-[25px] cursor-default items-center justify-center bg-white text-violet11">
                          <ChevronDownIcon />
                        </Select.ScrollDownButton>
                      </Select.Content>
                    </Select.Portal>
                  </Select.Root>
                </FormControl>
              </FormItem>
            )}
          />
        </div>
        <div className="space-y-2">
          <FormLabel>Other Compensation? Select all that apply</FormLabel>
          <FormField
            key={`equityAndStandardBenefits-${existingEmployment?.id}`}
            control={form.control}
            name="otherCompensation.equityAndStandardBenefits"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3">
                <FormControl>
                  <Checkbox
                    field={field}
                    label="Equity and Standard Benefits"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            key={`otherGuaranteedPay-${existingEmployment?.id}`}
            control={form.control}
            name="otherCompensation.otherGuaranteedPay"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3">
                <FormControl>
                  <Checkbox
                    field={field}
                    label="Other Guaranteed Pay"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="flex flex-row gap-3 w-full">
            <FormField
              key={`other-${existingEmployment?.id}`}
              control={form.control}
              name="otherCompensation.other"
              render={({ field }) => (
                <FormItem className="flex flex-row items-start w-full">
                  <FormControl>
                    <div className="flex flex-row gap-2 w-full items-start">
                      <Checkbox
                        field={{
                          ...field,
                          value: otherCompensation.length > 0,
                          onChange: (checked) => field.onChange(checked ? ' ' : '')
                        }}
                        label=""
                      />
                      <Input {...field} placeholder="Other pay" />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>

     
          <FormField
            key={`isFullTime-${existingEmployment?.id}`}
            control={form.control}
            name={'isFullTime'}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Is this a full-time position?</FormLabel>
                <FormControl className="flex items-center gap-2">
                  <FormLabel className="text-primary" htmlFor="isFullTimeYes"><input {...form.register("isFullTime")} type='radio' id="isFullTimeYes" name="isFullTime" value={'yes'} className="w-4 h-4" />Yes</FormLabel>
                </FormControl>
                <FormControl className="flex items-center gap-2">
                  <FormLabel className="text-primary" htmlFor="isFullTimeNo"><input {...form.register("isFullTime")} type='radio' id="isFullTimeNo" name="isFullTime" value={'no'} className="w-4 h-4" />No</FormLabel>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            key={`hoursPerWeek-${existingEmployment?.id}`}
            control={form.control}
            name="hoursPerWeek"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Hours Per Week</FormLabel>
                <FormControl>
                  <Input type="number" {...field} onChange={e => field.onChange(parseFloat(e.target.value))} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
  
      </FieldsContainer>
      <CTAContainer onBack={onBack}>
        <Button className="ml-auto text-md rounded-sm px-7 py-5" variant="accent" type="submit">Next</Button>
      </CTAContainer>
    </EmployerOnboardingContainer>
  );
};


