import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { OnboardingContainer } from "@pages/onboarding/shared/onboarding-container";

export const FormLoading = () => (
  <OnboardingContainer
    title={<div className="w-1/2 h-11 bg-slate-200 animate-pulse rounded" />}
    subtitle={<div className="w-3/4 h-4 bg-slate-200 animate-pulse rounded" />}
    progress={0}
    name={`loading-form`}
  >
    <div className="flex flex-col gap-3">
      <div className="w-1/6 h-4 bg-slate-200 animate-pulse rounded" />
      <div className="w-3/4 h-8 bg-slate-200 animate-pulse rounded" />
      <div className="w-1/6 h-4 bg-slate-200 animate-pulse rounded" />
      <div className="w-3/4 h-8 bg-slate-200 animate-pulse rounded" />
      <div className="w-1/6 h-4 bg-slate-200 animate-pulse rounded" />
      <div className="w-3/4 h-8 bg-slate-200 animate-pulse rounded" />
    </div>
    <CTAContainer>
      <div className="w-24 h-10 rounded bg-slate-200 animate-pulse" />
    </CTAContainer>
  </OnboardingContainer>
)