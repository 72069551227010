import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@components/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { z } from "zod";
import { Input } from "@components/input";
import { Button } from "@components/button";
import { PlymouthUser } from "@utils/types";
import { useCallback, useState } from "react";
import {
  useCreateUserMutation,
  useCreateCompanyMemberMutation,
  useUserDatumUpdatesMutation,
  useUpdateCompanyMemberByCompanyIdAndUserIdMutation,
} from "@codegen/index";

import { EmployerOnboardingContainer } from "@pages/onboarding/employer/forms/employer-onboarding-container";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { YesNo } from "@pages/onboarding/types";
import { FieldsContainer } from "@pages/onboarding/shared/fields-container";
import { useLogError } from "@utils/error";
import { CompanyUserRole, CreateCompanyMemberInput, CreateUserMutationVariables, UpdateCompanyMemberByCompanyIdAndUserIdInput, UpdateUserDatumInput, User } from "@codegen/schema";
import * as React from 'react'
import { useEmployerOnboarding } from '../../employer-onboarding';
import { Info } from "lucide-react";
import { HoverCard, HoverCardArrow, HoverCardContent, HoverCardTrigger } from "@components/hoverCard";
import { Lightbulb, Warning } from "@phosphor-icons/react";
import { Skeleton } from "@components/skeleton";

const yesNoSchema = z.object({
  isAuthorizedSignatory: z.enum(['yes', 'no'], {
    errorMap: (issue, ctx) => ({ message: 'Please select whether you are an authorized company signatory' })
  }).or(z.boolean()).transform(val => 
    typeof val === 'boolean' ? (val ? 'yes' : 'no') : val
  )
})
const personalSchema = z.object({
  title: z.string().min(1, "Please provide a title"),
  firstName: z.string().min(1, "Please provide a first name"),
  lastName: z.string().min(1, "Please provide a last name"),
  email: z.string().min(1, "Please provide an email"),
  phone: z.string().min(1, "Please provide a phone number"),
}).and(yesNoSchema);


type NewContactFormValues = z.infer<typeof personalSchema>;

export const NewContactForm = () => {
  const { onSubmit, company, onBack, contact, members, refetchCompany } = useEmployerOnboarding();
  const [status, setStatus] = useState<"idle" | "loading" | "error">("idle");
  const [createUserMutation] = useCreateUserMutation();
  const [updateUserDatum] = useUserDatumUpdatesMutation();
  const [addCompanyMember] = useCreateCompanyMemberMutation()
  const [updateCompanyMembers] = useUpdateCompanyMemberByCompanyIdAndUserIdMutation()

  const logError = useLogError()
  const [showForm, setShowForm] = useState(false)

  const existingSignatories = React.useMemo(() => {
    return members.filter(m => m?.isAuthorizedSignatory).map(m => {
      const user = m?.userByUserId
      let name = user?.fullName?.split(' ')
      const parsedMember = user ? {
        title: user.userDatumById?.role,
        firstName: name?.shift(),
        lastName: name?.join(' '),
        email: user.email,
        phone: user.userDatumById?.phoneNumber
      } : undefined
      return parsedMember
    }, [])
  }, [members])

  const defaultSignatory = existingSignatories[existingSignatories.length - 1]

  const isCurrentUserSignatory = React.useMemo(() => {
    const user = members.find(u => u?.userByUserId?.id === contact?.id)
    return user?.isAuthorizedSignatory
  }, [members, contact])

  const form = useForm<NewContactFormValues>({
    defaultValues: {
      isAuthorizedSignatory: typeof isCurrentUserSignatory === 'boolean' ? isCurrentUserSignatory ? 'yes' : 'no' : undefined
    },
    resolver: zodResolver(showForm ? personalSchema : yesNoSchema),
  });

  const isAuthorizedSignatory = form.watch('isAuthorizedSignatory')
  const updateUserMember = useCallback(async (authorizedSignatory: boolean) => {
    if (contact?.id && company?.value) {
      try {
        const result = await updateCompanyMembers({
          variables: {
            input: {
              clientMutationId: '',
              userId: contact.id,
              companyId: company.value,
              companyMemberPatch: {
                isAuthorizedSignatory: authorizedSignatory,
              } as UpdateCompanyMemberByCompanyIdAndUserIdInput['companyMemberPatch'],
            },
          },
        });

        if (result?.errors) {
          throw result.errors[0];
        }

        await refetchCompany()
      } catch (error) {
        logError(error, {
          message: 'Failed to update company member'
        });
      }
    }
  }, [updateCompanyMembers, contact, company, refetchCompany]);
  React.useEffect(() => {
    if (isAuthorizedSignatory === 'yes') {
      setShowForm(false)
    } else if (isAuthorizedSignatory === 'no' && !existingSignatories?.length) {
      setShowForm(true)
    }
  }, [isAuthorizedSignatory, setShowForm])

  React.useEffect(() => {
    if (isAuthorizedSignatory === 'yes') {
      updateUserMember(true)
    } else if (isAuthorizedSignatory === 'no') {
      updateUserMember(false)
    }
  }, [isAuthorizedSignatory, updateUserMember])

  const fields: {
    name: keyof NewContactFormValues;
    label: string;
    placeholder?: string;
  }[] = [
      {
        name: "firstName",
        label: "First name",
      },
      {
        name: "lastName",
        label: "Last name",
      },
      {
        name: "title",
        label: "Title",
        placeholder: "CEO",
      },
      {
        name: "email",
        label: "Email address",
        placeholder: "hi@google.com",
      },
      {
        name: "phone",
        label: "Phone number",
        placeholder: "+16504438260",
      },
    ];

  const createUser = useCallback(
    async (x: NewContactFormValues) => {
      try {
        setStatus("loading");
        const { data, errors: createUserError } = await createUserMutation({
          variables: {
            input: {
              clientMutationId: '',
              user: {
                fullName: `${x.firstName} ${x.lastName}`,
                email: x.email,
              } as CreateUserMutationVariables['input']['user'],
            },
          },
        });

        if (!data?.createUser?.user?.id) {
          throw `Error creating user, unable to update user datum`
        }

        const updateUserDatumResult= await updateUserDatum({
          variables: {
            input: {
              clientMutationId: '',
              id: data.createUser.user.id,
              userDatumPatch: {
                phoneNumber: x.phone,
                role: x.title
              } as UpdateUserDatumInput['userDatumPatch'],
            },
          },
        });

        const addCompanyMemberResult = await addCompanyMember({
          variables: {
            input: {
              clientMutationId: '',
              companyMember: {
                userId: data?.createUser?.user?.id,
                companyId: company.value,
                role: CompanyUserRole.Admin,
                isAuthorizedSignatory: true
              } as CreateCompanyMemberInput['companyMember'],
            },
          }
        });

        if (addCompanyMemberResult?.errors) {
          // Fails silently and log to fix later
          logError(addCompanyMemberResult.errors[0], {
            userId: data?.createUser?.user?.id,
            companyId: company.value,
            message: 'Unable to add user to company members'
          })
        }

        if (createUserError) {
          throw createUserError[0]
        }

        if (updateUserDatumResult?.errors) {
          logError(updateUserDatumResult.errors[0], {
            message: 'Unable to update user datum'
          })
        }

      } catch (exception) {
        logError(exception)
        setStatus("error")
      }
    },
    [onSubmit, updateUserDatum, createUserMutation, addCompanyMember, setStatus, ]
  );

  const handleSubmit = useCallback(async (values: NewContactFormValues) => {
    try {
      if (values.isAuthorizedSignatory === 'yes') {
        onSubmit({
          isAuthorizedSignatory: true,
          // Add any other relevant user information here
        });
      } else if (existingSignatories && existingSignatories.length && !showForm) {
        onSubmit(existingSignatories[0]);
      } else if (showForm) {
        await createUser(values);
        refetchCompany()
        onSubmit({
          ...values,
          isAuthorizedSignatory: false
        });
      } else {
        throw new Error('No condition met, not calling onSubmit')
      }
    } catch (error) {
      logError(error, {
        message: 'Error in handleSubmit in add-new-contact'
      })
      setStatus('error')
    }
  }, [onSubmit, existingSignatories, showForm, createUser]);

  return (
    <EmployerOnboardingContainer
      title="Authorized Signatory"
      subtitle="Let's kick things off right away."
      progress={0}
      form={form}
      onSubmit={handleSubmit}
      name="add_new_contact"
    >
      {
          <FieldsContainer status={status} form={form} errorMessage="Please submit a valid authorized signatory">
            <FormField
              key={fields.length + 1}
              control={form.control}
              name={'isAuthorizedSignatory'}
              render={({ field }) => (
                <div>
                  <FormLabel className="text-md flex items-center gap-1 font-bold text-primary">Are you an authorized company signatory?
                    <HoverCard openDelay={0}>
                      <HoverCardTrigger>
                        <Button type="button" variant="link" className="p-0">
                          <Info size={16} />
                        </Button>
                      </HoverCardTrigger>
                      <HoverCardContent side="right">
                        <p>An authorized signatory is an individual who has been granted legal authority to sign documents on behalf of an organization or entity.</p>
                        <p>If you are also the petition's beneficiary (e.g., a founder), we will need a separate authorized signatory for the company.</p>
                        <HoverCardArrow className="fill-white" width={15} height={7} />
                      </HoverCardContent>
                    </HoverCard>
                  </FormLabel>
                  <p className="text-sm mb-2 text-muted-foreground">This person will sign the U.S. employment offer letter and the immigration forms. This person should have a title at the U.S. company (e.g. CEO, Head of People).
                  </p>
                  <p className="font-bold text-sm mb-2 flex items-center gap-1"><Warning size={16} className="text-orange-400" /> If you are the beneficiary for the case, you cannot be the authorized signatory.</p>
                  <p className="text-sm mb-3 text-muted-foreground"><b>Tip</b>: If there is no authorized signatory at the U.S. company, you may list a U.S. company Board Member or investor as an authorized signatory, though please create a company email address for them.</p>
                  <FormControl className="flex items-center gap-2 mb-2">
                    <FormLabel data-testid="isAuthorizedSignatoryYesLabel" className="text-md text-primary" htmlFor="isAuthorizedSignatoryYes">
                      <input data-testid="isAuthorizedSignatoryYes" {...form.register("isAuthorizedSignatory")} type='radio' id="isAuthorizedSignatoryYes" name="isAuthorizedSignatory" value={'yes'} />I am an authorized company signatory.</FormLabel>
                  </FormControl>
                  <FormControl className="flex items-center gap-2">
                    <FormLabel data-testid="isAuthorizedSignatoryNoLabel" className="text-md text-primary" htmlFor="isAuthorizedSignatoryNo"><input data-testid="isAuthorizedSignatoryNo" {...form.register("isAuthorizedSignatory")} type='radio' id="isAuthorizedSignatoryNo" name="isAuthorizedSignatory" value={'no'} />I am <b>NOT</b> an authorized company signatory.</FormLabel>
                  </FormControl>
                  <FormMessage />
                </div>
              )}
            />
            {
              showForm ? (
                <div className="animate-in fade-in duration-300">
                  <h2 className="font-semibold mb-2 text-sm">Please add the best signatory information</h2>
                  <div className="grid grid-cols-2 gap-2">
                    {fields.map((x, i) => (
                      <FormField
                        key={i}
                        control={form.control}
                        name={x.name}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel className="text-md">{x.label}</FormLabel>
                            <FormControl>
                              <Input
                                {...field}
                                className="h-11"
                                placeholder={x.placeholder}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    ))}
                  </div>
                </div>
              ) : null
            }
            {
              (defaultSignatory && !showForm) ? (
                <div className="mt-3">
                  <h2 className="text-lg mb-2">Signatory</h2>
                  <div className="p-3 border shadow-sm w-96 bg-white rounded-xl mb-3">
                    <div className="text-lg font-bold">{defaultSignatory.firstName} {defaultSignatory.lastName}</div>
                    <div>{defaultSignatory.title}</div>
                    <div>{defaultSignatory.email}</div>
                    <div>{defaultSignatory.phone}</div>
                  </div>
                </div>
              ) : null
            }
            {
              isAuthorizedSignatory === 'no' ? (
                <div>
                  <Button type="button" className="text-accent hover:underline px-0" variant={'link'} onClick={() => setShowForm(true)}>
                    Add signatory
                  </Button>
                </div>
              ) : null
            }
          </FieldsContainer>
      }

      <CTAContainer onBack={onBack}>
        <Button
          variant="accent"
          className="ml-auto text-md rounded-sm px-7 py-5"
          disabled={typeof isAuthorizedSignatory !== 'string'}
          type="submit"
        >
          Next
        </Button>
      </CTAContainer>
    </EmployerOnboardingContainer>
  );
};
