import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@components/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { z } from "zod";
import { Input } from "@components/input";
import { Button } from "@components/button";
import { PlymouthUser, Team } from "@utils/types";
import { useCallback, useState } from "react";
import {
  useAllCompanyOfficesQuery,
  useCreateCompanyOfficeMutation,
} from "@codegen/index";
import { EmployerOnboardingContainer } from "@pages/onboarding/employer/forms/employer-onboarding-container";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import CountrySelect from "@components/formCountrySelect";
import { EmployerOnboardingPageType } from "@pages/onboarding/types";
import { FieldsContainer } from "@pages/onboarding/shared/fields-container";
import { useLogError } from "@utils/error";
import { CompanyOffice, CreateCompanyOfficeInput } from "@codegen/schema";
import { ErrorMessage } from "@hookform/error-message";
import { FormErrorMessage } from "@pages/onboarding/shared/error";
import { useEmployerOnboarding } from '@pages/onboarding/employer-onboarding';
import { X } from "@phosphor-icons/react";
import { FormLoading } from "@pages/onboarding/beneficiary/forms/loading";

const companyAddressSchema = z.object({
  street: z.string().min(1, { message: "Required" }),
  city: z.string().min(1, { message: "Required" }),
  state: z.string().min(1, { message: "Required" }),
  country: z.string().min(1, { message: "Required" }),
  zip: z.string().min(1, { message: "Required" }),
  label: z.string().min(1, { message: "Required" }),
});

export type CompanyAddressValues = z.infer<typeof companyAddressSchema>;
export type OfficeAddress = Pick<CompanyOffice, 'id' | 'label' | 'postalCode' | 'state' | 'street' | 'street2' | 'country' | 'city' | 'isEmployeeWorksite'>
export const AddBeneficiaryWorksiteForm = () => {
  const { onSubmit, company, onBack, onSkip } = useEmployerOnboarding();
  const [status, setStatus] = useState<"idle" | "loading" | "error">("idle");
  const [showForm, setShowForm] = useState(false)
  const [createOffice] = useCreateCompanyOfficeMutation()
  const { data: officeData, loading: officeLoading, refetch } = useAllCompanyOfficesQuery({
    variables: {
      id: company.value
    }
  })
  const existingWorksites = officeData?.companyById?.companyOfficesByCompanyId?.nodes
  const hasExistingWorksites = existingWorksites && existingWorksites.length > 0
  const form = useForm<CompanyAddressValues>({
    defaultValues: {
      country: 'US'
    },
    resolver: zodResolver(companyAddressSchema),
  });
  const { formState } = form
  const logError = useLogError()

  const doSubmitCompanyAddress = useCallback(
    async (data: CompanyAddressValues) => {
      if (company === null) {
        throw 'Company is not provided'
      }
      return await createOffice({
        variables: {
          input: {
            clientMutationId: '',
            companyOffice: {
              companyId: parseInt(company.value),
              city: data.city,
              country: data.country,
              state: data.state,
              street: data.street,
              postalCode: data.zip,
              label: data.label,
              isEmployeeWorksite: true
            } as CreateCompanyOfficeInput['companyOffice'],
          },
        },
      });
    },
    [company]
  );

  const handleSubmit = async () => {
    try {
      const isValid = await form.trigger()
      const values = form.getValues()
      if (showForm && isValid) {
        setStatus("loading");
        const { errors } = await doSubmitCompanyAddress(values)
        if (errors) {
          throw errors[0]
        }
        refetch()
        onSubmit({ data: values, key: 'newWorksiteAddress' })
      } else if (!showForm && existingWorksites && existingWorksites.length > 0) {
        onSubmit({ data: existingWorksites[0], key: 'newWorksiteAddress' })
      } else {
        throw new Error('Invalid form')
      }
    } catch (e) {
      const values = form.getValues()
      setStatus('error')
      logError(e, { values })
    }
  }

  React.useEffect(() => {
    if (!officeLoading && !hasExistingWorksites) {
      setShowForm(true)
    }
  }, [officeLoading, hasExistingWorksites, setShowForm])

  if (officeLoading) {
    return <FormLoading />
  }

  return (
    <EmployerOnboardingContainer
      title="Company and Worksite Address"
      cardTitle={"Please add the worksite address for the beneficiary — you cannot include a PO Box for the address."}
      progress={0}
      form={form}
      onSubmit={handleSubmit}
      name="add_worksite"
    >
      <div className="text-sm text-gray-500 mb-3">
        <b>For Founders and small companies (25 full time employees or less):</b> If you have provided a Delaware address or a residential address for the company mailing address, please ensure that you have secured an office or co-working site (e.g, a WeWork) address <b>prior to the targeted filing date</b> of your visa petition.
      </div>
      {
        (existingWorksites && existingWorksites.length > 0) && (
          <div>
            <FormLabel className="mb-2">Existing worksites</FormLabel>
            <ul>
              {
                existingWorksites.map((of => (
                  <li key={of?.id} className="bg-white border rounded px-3 py-2 mb-2">
                    <div>
                      <b>{of?.label}</b>
                    </div>
                    <div>{of?.street}, {of?.city}, {of?.state} {of?.postalCode}</div>
                  </li>
                )))
              }
            </ul>
          </div>
        )
      }
      {
        showForm ? (
          <FieldsContainer status={status}>
            {
              existingWorksites && existingWorksites.length > 0 && (
                <div>
                  <Button variant="link" className="text-accent pl-0 hover:text-blue-700" onClick={() => setShowForm(false)}><X size={20} /></Button>
                </div>
              )
            }
            <FormField
              control={form.control}
              name="street"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      {...field}
                      className="h-11"
                      placeholder="123 Main St."
                    />
                  </FormControl>
                  <ErrorMessage
                    errors={formState.errors}
                    name="street"
                    render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="city"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input {...field} className="h-11" placeholder="City" />
                  </FormControl>
                  <ErrorMessage
                    errors={formState.errors}
                    name="city"
                    render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                  />
                </FormItem>
              )}
            />
            <div className="w-full flex flex-row gap-x-2">
              <FormField
                control={form.control}
                name="state"
                render={({ field }) => (
                  <FormItem className="w-1/2">
                    <FormControl>
                      <Input
                        {...field}
                        className="h-11 w-full"
                        placeholder="State"
                      />
                    </FormControl>
                    <ErrorMessage
                      errors={formState.errors}
                      name="state"
                      render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                    />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="country"
                render={({ field }) => (
                  <FormItem className="w-1/2">
                    <CountrySelect
                      field={field}
                      onSelect={(x: string) => form.setValue("country", x)}
                    />
                    <ErrorMessage
                      errors={formState.errors}
                      name="country"
                      render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                    />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="zip"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input {...field} className="h-11" placeholder="Zip code" />
                  </FormControl>
                  <ErrorMessage
                    errors={formState.errors}
                    name="zip"
                    render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                  />
                </FormItem>
              )}
            />
            <FormLabel className="mt-3">Worksite name</FormLabel>
            <FormField
              control={form.control}
              name="label"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      {...field}
                      className="h-11"
                      placeholder="Building 32"
                    />
                  </FormControl>
                  <ErrorMessage
                    errors={formState.errors}
                    name="label"
                    render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                  />
                </FormItem>
              )}
            />
          </FieldsContainer>
        ) : (
          <div>
            <Button variant="link" className="text-accent px-0 hover:underline" onClick={() => setShowForm(true)}>Add worksite</Button>
          </div>
        )
      }
      <CTAContainer onBack={onBack}>
        <Button
          variant="accent"
          className="ml-auto text-md rounded-sm px-7 py-5"
          type="button"
          onClick={handleSubmit}
        >
          Next
        </Button>
      </CTAContainer>
    </EmployerOnboardingContainer >
  );
};
