import { Button } from "@components/button";
import Spinner from "@components/spinner";
import {
  CheckCircle,
  CloudArrowUp,
  File,
  Warning,
  X,
} from "@phosphor-icons/react";
import { cn } from "@utils/cn";
import { useCallback, useState } from "react";
import { useFilePicker } from "use-file-picker";
import { useDropzone } from "react-dropzone";
import * as React from "react";
type UploadFile = {
  id: string;
  file?: File;
  name: string;
  link?: string;
};

type UploadFileStatus = {
  id: string;
  file?: File;
  name: string;
  status: "uploading" | "success" | "error";
  link?: string;
};

const Uploader = (props: {
  onFileUpload: (
    file: File
  ) => Promise<{ success: boolean; link: string | undefined }>;
  onDeleteFile?: (file: UploadFile) => void;
  multiple?: boolean;
  allowDelete?: boolean;
  defaultFiles?: UploadFile[];
}) => {
  const {
    onFileUpload,
    multiple = false,
    allowDelete = false,
    defaultFiles = [],
    onDeleteFile,
  } = props;

  const [fileStatus, setFileStatus] = useState<UploadFileStatus[]>(
    defaultFiles.map((f) => ({
      file: f.file,
      status: "success",
      id: f.id,
      name: f.name,
      link: f.link,
    }))
  );

  const uploadFile = useCallback(
    async (file: File) => {
      const uuid = Math.random().toString(36).substring(7);

      setFileStatus((prev) => [
        ...prev,
        { file, status: "uploading", id: uuid, name: file.name },
      ]);
      const { success, link } = await onFileUpload(file);

      setFileStatus((prev) =>
        prev.map((f) => {
          if (f.id === uuid) {
            return {
              file,
              status: success ? "success" : "error",
              id: uuid,
              name: file.name,
              link,
            };
          }

          return f;
        })
      );
    },
    [onFileUpload]
  );

  
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (!multiple && fileStatus.length > 0) return;

      if (multiple) acceptedFiles.forEach(uploadFile);
      else uploadFile(acceptedFiles[0]);
    },
    [fileStatus.length, multiple, uploadFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const [openFileSelector] = useFilePicker({
    readAs: "DataURL",
    accept: [".pdf", ".jpg", ".jpeg", ".png", ".txt", ".html"],
    multiple,

    onFilesRejected: (errors) => {
      console.error(errors);
    },

    onFilesSuccessfulySelected: (files) => files.plainFiles.forEach(uploadFile),
  });

  return (
    <div
      className={cn(
        "w-full h-fit bg-onboarding rounded-xl border border-dashed border-black/50",
        isDragActive && "border-accent"
      )}
    >
      {fileStatus.length === 0 ? (
        <div
          className="flex flex-col items-center justify-center h-[152px] hover:cursor-pointer"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <CloudArrowUp className="w-10 h-10" />
          <Button variant="link" type="button" onClick={openFileSelector}>
            Drag files here or upload from computer
          </Button>
        </div>
      ) : (
        <div className="flex flex-col p-3 w-full items-center justify-center gap-y-1">
          {fileStatus.map((file, i) => (
            <div className="flex flex-row w-full items-center gap-x-1" key={i}>
              <div
                className={cn(
                  "flex flex-row p-3 gap-x-3 justify-between w-full border rounded-xl bg-paleblue",
                  file.status === "success" && "border-accent",
                  file.status === "error" && "border-orange-700",
                  file.status === "uploading" && "border-paleblue",
                  file.link != null && "hover:bg-white/80 hover:cursor-pointer"
                )}
                onClick={() => {
                  if (file.link != null) window.open(file.link, "_blank");
                }}
              >
                <div className="flex flex-row items-center gap-x-2">
                  <File size={24} />
                  <p className="text-sm font-medium">{file.name}</p>
                </div>
                {file.status === "uploading" && <Spinner size={24} />}
                {file.status === "success" && (
                  <CheckCircle size={24} className="text-accent" />
                )}
                {file.status === "error" && (
                  <Warning size={24} className="text-orange-700" />
                )}
              </div>

              {allowDelete && (
                <X size={16} onClick={() => onDeleteFile?.(file)} />
              )}
            </div>
          ))}
          {multiple && (
            <div
              {...getRootProps()}
              className="flex flex-row items-center gap-x-1 mt-3"
            >
              <Button type="button" onClick={openFileSelector} variant="link">
                <input {...getInputProps()} />
                Drop files here or click to upload
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Uploader;
