import { Button } from "@components/button";
import { EmployerOnboardingContainer } from "@pages/onboarding/employer/forms/employer-onboarding-container";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { useEmployerOnboarding } from '../../employer-onboarding';
import { usePersistedOnboardingMachine } from "../machines";
import { kebabCase } from "change-case";
import { useNavigate } from "react-router-dom";
import React from "react";

export const WelcomePage = () => {
  const { onSubmit, company } = useEmployerOnboarding();
  const { getOnboardingState } = usePersistedOnboardingMachine(company.value)
  const [hasPersistedState, setHasPersistedState] = React.useState(false)

  React.useEffect(() => {
    const restoredState = getOnboardingState()
    console.log(restoredState)
    if (restoredState?.value && restoredState.value !== 'welcome') {
      setHasPersistedState(true)
    }
  }, [getOnboardingState])


  const navigate = useNavigate()
  const onNext = () => {
    const restoredState = getOnboardingState()
    console.log(restoredState)
    if (restoredState?.value && restoredState.value !== 'welcome') {
      navigate(`/onboarding/employer/${kebabCase(restoredState.value)}`)
    } else {
      onSubmit({})
    }
  }

  return (
    <EmployerOnboardingContainer
      title="Welcome to Plymouth Employer Onboarding! "
      subtitle=""
      cardTitle={"Over the next few screens, we'll ask for a few details to get you set up."}
      progress={0}
      name="welcome"
    >
      <div className="flex flex-col gap-y-4 w-[500px] mt-4">
        <ol className="list-decimal px-5">
          <li className="mb-2">Primary Contact Information</li>
          <li className="mb-2">Corporate Documents (Articles of Incorporation, EIN)</li>
          <li className="mb-2">U.S. Company HQ and information</li>
        </ol>
      </div>
      <CTAContainer>
        <Button
          variant="accent"
          className="ml-auto text-md rounded-sm px-7 py-5"
          disabled={false}
          onClick={onNext}
        >
          { hasPersistedState ? 'Continue where you left off' : 'Next' }
        </Button>
      </CTAContainer>
    </EmployerOnboardingContainer>
  );
};
