import { Button } from '@components/button';
import { FormControl, FormItem, FormLabel } from '@components/form';
import { Input } from '@components/input';
import { zodResolver } from '@hookform/resolvers/zod';
import { CTAContainer } from '@pages/onboarding/shared/cta-container';
import { FieldsContainer } from '@pages/onboarding/shared/fields-container';
import { OnboardingContainer } from '@pages/onboarding/shared/onboarding-container';
import { OnboardingPageType } from '@pages/onboarding/types';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { ErrorMessage } from "@hookform/error-message"
import { FormErrorMessage } from '@pages/onboarding/shared/error';
import { useUpdateUserEmploymentByIdMutation, useUserEmploymentByIdQuery } from '@codegen/index';
import { useLogError } from '@utils/error';
import { UpdateUserEmploymentByIdInput, UserEmployment } from '@codegen/schema';
import { WorkflowEmploymentHistoryExhibit, WorkflowEmploymentModule } from '@codegen/enums';
import { UnexpectedErrorPage } from '@pages/onboarding/shared/unexpected-error';
import Spinner from '@components/spinner';
import { FormLoading } from './loading';

const employmentHistorySchema = z.object({
  employerName: z.string().min(1, 'Please confirm your employer name'),
  startDate: z.string().date('Please provide a start date'),
  lastDate: z.string().optional(),
  isCurrentEmployment: z.boolean(),
}).refine(({ startDate, lastDate, isCurrentEmployment }) => {
  if (!isCurrentEmployment) {
    return lastDate && lastDate > startDate
  } else {
    return true
  }
}, {
  path: ['lastDate'],
  message: 'Please provide a valid last date at the company.'
})

type EmploymentHistoryValues = z.infer<typeof employmentHistorySchema>;
export const EmploymentHistoryForm: React.FC<OnboardingPageType & {
  employment: Pick<UserEmployment, 'companyName' | 'id' | 'startDate' | 'endDate' | 'isCurrent'>
}> = ({ onSubmit, onBack, onSkip, employment, exhibit }) => {
  const [updateUserEmployment, { loading: saving }] = useUpdateUserEmploymentByIdMutation()
  const logError = useLogError()
  const methods = useForm({
    defaultValues: {
      employerName: employment?.companyName,
      startDate: employment?.startDate,
      lastDate: employment?.endDate ?? '',
      isCurrentEmployment: employment?.isCurrent
    },
    resolver: zodResolver(employmentHistorySchema),
  });

  const { register, formState, watch } = methods
  const handleSubmit = React.useCallback(async (data: EmploymentHistoryValues) => {
    try {
      const result = await updateUserEmployment({
        variables: {
          input: {
            clientMutationId: '',
            id: employment.id,
            userEmploymentPatch: {
              companyName: data.employerName,
              startDate: data.startDate,
              endDate: data.lastDate && !data.isCurrentEmployment ? data.lastDate : null,
              isCurrent: data.isCurrentEmployment
            } as UpdateUserEmploymentByIdInput['userEmploymentPatch']
          }
        }
      })

      if (result.errors) {
        throw result.errors[0]
      }

      onSubmit(data)
    } catch (error) {
      if (error instanceof Error) {
        methods.setError('root.serverError', { 
          type: error.name,
          message: error.message
        })
      }
      logError(error, { message: 'Error saving user employment', employment })
    }
  }, [updateUserEmployment, onSubmit, methods.setError])

  const isCurrentEmployment = watch('isCurrentEmployment')

  return (
    <FormProvider {...methods}>
      <OnboardingContainer
        title={"Employment details"}
        subtitle={(
          <p>Please provide details of the employer at <b>{employment?.companyName}</b></p>
        )}
        cardTitle={""}
        progress={0}
        onSubmit={handleSubmit}
        exhibit={exhibit}
        name={`employment-history-form`}
      >
        <FieldsContainer status={''}>
          <FormItem>
            <FormLabel>Company legal name</FormLabel>
            <FormControl>
              <Input
                {...register('employerName')}
                className="h-11"
                placeholder="Google Inc."
              />
            </FormControl>
            <ErrorMessage
              errors={formState.errors}
              name="employerName"
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FormItem>
          <div className="flex justify-between gap-3">
            <FormItem className="w-1/2">
              <FormLabel>Start date</FormLabel>
              <FormControl>
                <Input
                  {...register('startDate')}
                  aria-label="Date" type="date"
                  className="h-11"
                  placeholder="11/11/2020"
                />
              </FormControl>
              <ErrorMessage
                errors={formState.errors}
                name="startDate"
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </FormItem>
            <FormItem className="w-1/2">
              <FormLabel>Last date</FormLabel>
              <FormControl>
                <Input
                  {...register('lastDate')}
                  aria-label="Date" type="date"
                  className="h-11"
                  placeholder="11/11/2020"
                  disabled={!!isCurrentEmployment}
                />
              </FormControl>
              <ErrorMessage
                errors={formState.errors}
                name="lastDate"
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
              <label htmlFor='isCurrentEmployment' className="flex items-center mt-3 gap-2">
                <input type="checkbox" {...register('isCurrentEmployment')} id="isCurrentEmployment" />
                I currently work here
              </label>
            </FormItem>
          </div>
        </FieldsContainer>
        <CTAContainer onBack={onBack} onSkip={onSkip}>
          <Button
            variant="accent"
            className="ml-auto text-md rounded-sm px-7 py-5"
            disabled={saving}
            type="submit"
          >
            {saving ? <Spinner /> : 'Next'}
          </Button>
        </CTAContainer>
      </OnboardingContainer>
    </FormProvider >
  );
}

export const EmploymentHistoryFormController: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, onSubmit, module } = props
  const { company } = exhibit as WorkflowEmploymentHistoryExhibit
  const { employments } = module as WorkflowEmploymentModule
  const employmentObj = employments.find(e => e.companyId === company.companyId)

  const { data: employmentData, error, refetch, loading } = useUserEmploymentByIdQuery({
    variables: {
      id: employmentObj?.employmentId!
    }
  })

  const logError = useLogError()
  React.useEffect(() => {
    if (error && employmentObj) {
      logError(`Unable to find user employment ID: ${employmentObj.employmentId}`, {
        employmentObj,
        error
      })
    }
  }, [error, employmentObj])

  const handleSubmit = React.useCallback(async (data: EmploymentHistoryValues) => {
    onSubmit(data)
    refetch()
  }, [onSubmit, refetch])

  if (error || (!loading && !employmentData?.userEmploymentById)) {
    return <UnexpectedErrorPage {...props} error={error} />
  }

  if (employmentData && employmentData.userEmploymentById) {
    return (
      <EmploymentHistoryForm {...props} onSubmit={handleSubmit} employment={employmentData.userEmploymentById} />
    )
  }

  return <FormLoading />
}