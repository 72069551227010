import { ExhibitStatusType } from '@codegen/schema';
import { ModuleStatus, StrategyExhibit, StrategyModule } from '@utils/types';
import { setup, assign } from 'xstate';

export interface OnboardingContext {
  modules: StrategyModule[];
  currentModuleIndex: number;
  currentExhibitIndex: number;
  completedModules: StrategyModule[];
  followUpTasks: { exhibitId: string; message: string; status: string }[];
}

type OnboardingEvent =
  | { type: 'NEXT_EXHIBIT' }
  | { type: 'PREV_EXHIBIT' }
  | { type: 'NEXT_MODULE' }
  | { type: 'PREV_MODULE' }
  | { type: 'SET_DATA'; moduleId: string; exhibitId: string; status?: StrategyExhibit['status']; data: any }
  | { type: 'SET_EXHIBIT_STATUS'; moduleId: string; exhibitId: string; status: StrategyExhibit['status']; }
  | { type: 'VALIDATE' }
  | { type: 'INIT_MODULES'; modules: StrategyModule[], defaultExhibitIndex: number, defaultModuleIndex: number };

function loadInitialContext(): OnboardingContext {
  const viewTeamId = localStorage.getItem('viewTeam');
  const viewAccountId = localStorage.getItem('viewAccountId');
  const savedModules = localStorage.getItem('onboardingState' + viewTeamId + viewAccountId);
  const savedCurrentModuleIndex = localStorage.getItem('currentModuleIndex' + viewTeamId + viewAccountId);
  const savedCurrentExhibitIndex = localStorage.getItem('currentExhibitIndex' + viewTeamId + viewAccountId);

  if (savedModules && savedCurrentModuleIndex !== null && savedCurrentExhibitIndex !== null) {
    return {
      modules: JSON.parse(savedModules),
      currentModuleIndex: parseInt(savedCurrentModuleIndex, 10),
      currentExhibitIndex: parseInt(savedCurrentExhibitIndex, 10),
      completedModules: [],
      followUpTasks: [],
    };
  }

  // Default context if no saved state is found
  return {
    modules: [],
    currentModuleIndex: 0,
    currentExhibitIndex: 0,
    completedModules: [],
    followUpTasks: [],
  };
}

export const onboardingMachine = setup({
  types: {
    context: {} as OnboardingContext,
    events: {} as OnboardingEvent,
  },
  actions: {
    initializeModules: assign({
      modules: ({ event }) => event.type === 'INIT_MODULES' ? event.modules : [],
      currentModuleIndex: ({ event }) => event.type === 'INIT_MODULES' ? event.defaultModuleIndex : 0,
      currentExhibitIndex: ({ event }) => event.type === 'INIT_MODULES' ? event.defaultExhibitIndex : 0,
      completedModules: () => [],
      followUpTasks: () => [],
    }),
    setExhibitData: assign({
      modules: ({ context, event }) => {
        if (event.type === 'SET_DATA') {
          const { moduleId, exhibitId, data, status } = event;
          return context.modules.map((module) =>
            module.id === moduleId
              ? {
                ...module,
                status: module.status === 'pending' ? 'in_progress' : module.status,
                exhibits: module.exhibits.map((exhibit) =>
                  exhibit.id === exhibitId
                    ? { ...exhibit, data, status: status ?? 'in_progress' as const }
                    : exhibit
                ),
              }
              : module
          );
        }
        return context.modules;
      },
    }),
    setExhibitStatus: assign({
      modules: ({ context, event }) => {
        if (event.type === 'SET_EXHIBIT_STATUS') {
          const { moduleId, exhibitId, status } = event;
          return context.modules.map((module) =>
            module.id === moduleId
              ? {
                ...module,
                status: module.status === 'pending' ? 'in_progress' : module.status,
                exhibits: module.exhibits.map((exhibit) =>
                  exhibit.id === exhibitId
                    ? { ...exhibit, status: status }
                    : exhibit
                ),
              }
              : module
          );
        }
        return context.modules;
      },
    }),
    nextExhibit: assign({
      currentExhibitIndex: ({ context }) => context.currentExhibitIndex + 1,
    }),
    prevExhibit: assign({
      currentExhibitIndex: ({ context }) => Math.max(context.currentExhibitIndex - 1, 0),
    }),
    completeModule: assign({
      modules: ({ context }) => {
        return context.modules.map((module, index) =>
          index === context.currentModuleIndex ? { ...module, status: ModuleStatus.Completed } : module
        );
      },
      completedModules: ({ context }) => [
        ...context.completedModules,
        context.modules[context.currentModuleIndex],
      ],
      currentExhibitIndex: () => 0,
    }),
    nextModule: assign({
      currentModuleIndex: ({ context }) => {
        console.log("nextModule action", context.currentModuleIndex)
        return context.currentModuleIndex + 1
      },
      currentExhibitIndex: () => 0,
    }),
    prevModule: assign({
      currentModuleIndex: ({ context }) => Math.max(context.currentModuleIndex - 1, 0),
      currentExhibitIndex: ({ context }) => context.modules[context.currentModuleIndex - 1].exhibits.length - 1,
    }),
    saveToLocalStorage: ({ context }) => {
      const viewTeamId = localStorage.getItem('viewTeam');
      const viewAccountId = localStorage.getItem('viewAccountId');
      localStorage.setItem('onboardingState' + viewTeamId + viewAccountId, JSON.stringify(context.modules));
      localStorage.setItem('currentModuleIndex' + viewTeamId + viewAccountId, context.currentModuleIndex.toString());
      localStorage.setItem('currentExhibitIndex' + viewTeamId + viewAccountId, context.currentExhibitIndex.toString());
    },
    validateExhibits: assign({
      followUpTasks: ({ context }) => {
        const tasks = [];
        for (let i = 0; i < context.modules.length; i++) {
          const module = context.modules[i]
          for (let j = 0; j < module.exhibits.length; j++) {
            const exhibit = module.exhibits[j]
            if (exhibit.required && ['pending', 'in_progress'].includes(exhibit.status)) {
              tasks.push({
                exhibitId: exhibit.id,
                moduleId: module.id,
                moduleIndex: i,
                exhibitIndex: j,
                message: `Please provide the required data for ${exhibit.name}: ${exhibit.description}`,
                status: 'pending',
              });
            }
          }
        }
        return tasks;
      },
    }),
  },
  guards: {
    hasMoreExhibitsInModule: ({ context }) => {
      return context.currentExhibitIndex < context.modules[context.currentModuleIndex].exhibits.length - 1
    },
    isLastExhibitInModule: ({ context }) =>
      context.currentExhibitIndex === context.modules[context.currentModuleIndex].exhibits.length - 1,
    isFirstExhibitInModule: ({ context }) =>
      context.currentExhibitIndex === 0,
    hasMoreModules: ({ context }) => {
      return context.currentModuleIndex < context.modules.length - 1
    },
    hasFollowupTasks: ({ context }) =>
      context.modules.some(m => m.exhibits.some(ex => ['pending', 'in_progress'].includes(ex.status)))
  },
}).createMachine({
  id: 'onboarding',
  initial: 'initializing',
  context: ({ input }) => ({
    ...loadInitialContext(),
    ...input
  }),
  states: {
    initializing: {
      on: {
        INIT_MODULES: {
          target: 'collectingData',
          actions: ['initializeModules', 'saveToLocalStorage'],
        },
      },
    },
    collectingData: {
      on: {
        SET_DATA: {
          actions: ['setExhibitData', 'saveToLocalStorage'],
        },
        SET_EXHIBIT_STATUS: {
          actions: ['setExhibitStatus', 'saveToLocalStorage'],
        },
        NEXT_EXHIBIT: [
          {
            target: 'collectingData',
            actions: ['nextExhibit', 'saveToLocalStorage'],
            guard: 'hasMoreExhibitsInModule',
          },
          {
            target: 'nextModule',
            actions: ['completeModule', 'saveToLocalStorage'],
            guard: 'isLastExhibitInModule',
          },
        ],
        PREV_EXHIBIT: {
          actions: ['prevExhibit', 'saveToLocalStorage'],
        },
        PREV_MODULE: {
          target: 'collectingData',
          actions: ['prevModule', 'saveToLocalStorage'],
        },
        VALIDATE: {
          actions: ['validateExhibits', 'saveToLocalStorage'],
          target: 'validation',
        },
      },
    },
    nextModule: {
      always: [
        {
          target: 'collectingData',
          actions: ['nextModule', 'saveToLocalStorage'],
          guard: 'hasMoreModules',
        },
        {
          target: 'followup',
          actions: ['completeModule'],
          guard: 'hasFollowupTasks'
        },
        {
          target: 'completed',
          actions: ['completeModule'],
        }
      ],
    },
    validation: {
      always: {
        target: 'collectingData',
        actions: ['saveToLocalStorage', ({ context }) => {
          if (context.followUpTasks.length > 0) {
            console.log('Follow-up tasks needed:', context.followUpTasks);
          } else {
            console.log('All required data is complete');
          }
        }],
      },
    },
    followup: {
      type: 'final',
    },
    completed: {
      type: 'final',
    },
  },
});
