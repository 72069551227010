import { useAllUserDocumentsByTypeQuery } from '@codegen/index';
import { CompanyDocumentType, ImmigrationDocumentType, IndividualDocumentType } from '@codegen/schema';
import { Button } from '@components/button';
import Spinner from '@components/spinner';
import Uploader from '@components/uploader/uploader';
import { CTAContainer } from '@pages/onboarding/shared/cta-container';
import { FieldsContainer } from '@pages/onboarding/shared/fields-container';
import { OnboardingContainer } from '@pages/onboarding/shared/onboarding-container';
import { OnboardingPageType } from '@pages/onboarding/types';
import { api } from '@utils/api';
import { useLogError } from '@utils/error';
import { useJwt } from '@utils/hooks';
import React from 'react';
import { z } from 'zod';
import * as changeCase from 'change-case'
import { FileFootnote } from '@pages/onboarding/shared/footnotes';
import { CriticalRoleDistinguishedReputation, CriticalRoleInternalDocuments } from '@pages/onboarding/workflows/descriptions';
import { WorkflowAwardExhibit } from '@codegen/enums';

export const fileFormSchema = z.object({
  files: z.string().array().min(1, 'Please provide at least one file')
});

export type FileFormType = z.infer<typeof fileFormSchema>;
export const OneIndividualDocumentForm: React.FC<OnboardingPageType & { fileType: IndividualDocumentType, title: string, subtitle: React.ReactNode, cardTitle: React.ReactNode }> = ({
  contact, onBack, onSubmit, onSkip, fileType, title, subtitle, cardTitle, footnote, exhibit
}) => {
  const { required } = exhibit
  const [fileUploaded, setFileUploaded] = React.useState(false);
  const token = useJwt()
  const logError = useLogError()

  const { data, refetch } = useAllUserDocumentsByTypeQuery({
    variables: {
      userId: contact.id,
      documentType: fileType
    }
  })

  const existingFiles = React.useMemo(() => {
    return data?.allUserDocuments?.nodes?.map(node => node?.fileByFileId).filter(n => !!n)
  }, [data])

  React.useEffect(() => {
    if (existingFiles && existingFiles?.length > 0 && !fileUploaded) {
      setFileUploaded(true)
    }
  }, [existingFiles, setFileUploaded])

  const handleSubmit = async () => {
    if (fileUploaded) {
      const res = await refetch()
      const files = res.data.allUserDocuments?.nodes?.map(n => n?.fileByFileId?.id)
      onSubmit({files})
    } else if (!required) {
      onSkip?.()
    }
  }

  const onFileUpload = React.useCallback(
    async (file: File) => {
      try {
        const data = await api.files.uploadIndividualDoc(
          file,
          token,
          contact.id,
          fileType
        );
        if (data.success) setFileUploaded(true);
        const { id } = data

        return {
          success: data.success,
          link: "",
          fileId: id
        };
      } catch (exception) {
        logError(exception, {
          contactId: contact.id,
          fileType
        })
        return {
          success: false,
          link: ""
        }
      }
    },
    [contact.id, token, fileType]
  );

  return (
    <OnboardingContainer
      key={exhibit.id}
      title={title}
      subtitle={subtitle}
      cardTitle={cardTitle}
      progress={0}
      onSubmit={handleSubmit}
      exhibit={exhibit}
      name={`${changeCase.kebabCase(fileType)}-form`}
      footnote={footnote}
    >
      <FieldsContainer status={''}>
        <div>
          <Button variant="link" className="pl-0">
            {changeCase.sentenceCase(fileType)}
          </Button>
          {existingFiles == null && <Spinner />}
          {existingFiles != null && (
            <Uploader
              key={exhibit.id}
              multiple
              onFileUpload={onFileUpload}
              defaultFiles={existingFiles.map((x) => ({
                id: x.id ?? "",
                name: x.name ?? "",
              }))}
            />
          )}
        </div>
      </FieldsContainer>
      <CTAContainer onBack={onBack} onSkip={required ? undefined : onSkip}>
        <Button
          variant="accent"
          className="ml-auto text-md rounded-sm px-7 py-5"
          type="submit"
          disabled={required && !fileUploaded}
        >
          Next
        </Button>
      </CTAContainer>
    </OnboardingContainer>
  );
}

export const TwoFilesForm: React.FC<OnboardingPageType & { title: string, subtitle: React.ReactNode, cardTitle: React.ReactNode, files: IndividualDocumentType[] }> = ({
  contact, onBack, onSubmit, onSkip, files, title, subtitle, cardTitle, footnote, exhibit, petition,
}) => {
  const { required } = exhibit
  const [fileUploaded, setFileUploaded] = React.useState(false);
  const [file2Uploaded, setFile2Uploaded] = React.useState(false);
  const token = useJwt()
  const logError = useLogError()
  const [file1, file2] = files

  const { data: file1Data, refetch: refetch1, loading: file1Loading } = useAllUserDocumentsByTypeQuery({
    variables: {
      userId: contact.id,
      documentType: file1
    }
  })

  const { data: file2Data, refetch: refetch2, loading: file2Loading } = useAllUserDocumentsByTypeQuery({
    variables: {
      userId: contact.id,
      documentType: file2
    }
  })

  const existingFiles1 = file1Data?.allUserDocuments?.nodes?.map(node => node?.fileByFileId).filter(n => !!n) ?? []
  const existingFiles2 = file2Data?.allUserDocuments?.nodes?.map(node => node?.fileByFileId).filter(n => !!n) ?? []
  const handleSubmit = async () => {
    if (fileUploaded) {
      const p1 = refetch1()
      const p2 = refetch2()

      const [res1, res2] = await Promise.all([p1, p2])
      const files = [...(res1.data.allUserDocuments?.nodes ?? []), ...(res2.data.allUserDocuments?.nodes ?? [])].map(n => n?.fileByFileId?.id)
      onSubmit({files})
    }
  }

  React.useEffect(() => {
    if (existingFiles1.length > 0 && !fileUploaded) {
      setFileUploaded(true)
    }
    if (existingFiles2.length > 0 && !file2Uploaded) {
      setFile2Uploaded(true)
    }
  }, [existingFiles1, existingFiles2, setFileUploaded])

  const onFileUpload = React.useCallback(
    async (file: File, fileType: IndividualDocumentType) => {
      try {
        const data = await api.files.uploadIndividualDoc(
          file,
          token,
          contact.id,
          fileType
        );
        if (data.success) setFileUploaded(true);
        const { id } = data

        return {
          success: data.success,
          link: "",
          fileId: id
        };
      } catch (exception) {
        logError(exception, {
          contactId: contact.id,
          exhibitId: exhibit.id,
          petitionId: petition.id,
        })
        return {
          success: false,
          link: ""
        }
      }
    },
    [contact, exhibit, petition, token]
  );

  return (
    <OnboardingContainer
      title={title}
      subtitle={subtitle}
      cardTitle={cardTitle}
      progress={0}
      onSubmit={handleSubmit}
      exhibit={exhibit}
      name={`${changeCase.kebabCase(file1)}-${changeCase.kebabCase(file2)}-form`}
      footnote={footnote}
    >
      <FieldsContainer status={''}>
        <div>
          <Button variant="link" className="pl-0">
            {changeCase.sentenceCase(file1)}
          </Button>
          {existingFiles1 == null && <Spinner />}
          {existingFiles2 != null && (
            <Uploader
              multiple
              onFileUpload={(file: File) => onFileUpload(file, file1)}
              defaultFiles={existingFiles1.map((x) => ({
                id: x.id ?? "",
                name: x.name ?? "",
              }))}
            />
          )}
        </div>
        <div>
          <Button variant="link" className="pl-0">
            {changeCase.sentenceCase(file2)}
          </Button>
          {existingFiles1 == null && <Spinner />}
          {existingFiles2 != null && (
            <Uploader
              multiple
              onFileUpload={(file: File) => onFileUpload(file, file2)}
              defaultFiles={existingFiles2.map((x) => ({
                id: x.id ?? "",
                name: x.name ?? "",
              }))}
            />
          )}
        </div>
      </FieldsContainer>
      <CTAContainer onBack={onBack} onSkip={required ? undefined : onSkip}>
        <Button
          variant="accent"
          className="ml-auto text-md rounded-sm px-7 py-5"
          type="button"
          disabled={required && (!fileUploaded || !file2Uploaded)}
          onClick={handleSubmit}
        >
          Next
        </Button>
      </CTAContainer>
    </OnboardingContainer>
  );
}

export const OfferLetterForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.OfferLetter}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>Please upload your job offer letter</p>}
    />
  )
}

export const EVLetterForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.EvLetter}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>Please upload your job employment verification letter</p>}
    />
  )
}

export const OrgChartForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.OrgChart}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>Please upload your organization chart</p>}
    />
  )
}

export const CalendarForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.Calendar}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>Please upload your calendar</p>}
    />
  )
}

export const MembershipLetterOrMailForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.ProofOfMembershipLetterOrEmail}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const MembershipProfilePagelForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.ProofOfMembershipProfilePage}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const JudgesProfileForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.Judges}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const JudgingInviteOrConfirmationForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.JudgingInviteOrConfirmation}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const JudgingSelectionCriteriaForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.EvidenceOfSelectionCriteria}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const JudgingScreenshotOfEventOrPrizesForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.ScreenshotOfEventOrPrizes}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const OCProductRoadmapForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.ProductRoadmap}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const OCTechnicalDocumentsForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.TechnicalDoc}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const OCOriginalCodeForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.OriginalCode}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}
export const OCUserCountOrRevenueForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.UserCountOrRevenue}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const PitchDeckForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.PitchDeck}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const VCEvidenceForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.VcEvidence}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const AwardCapTableForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.CapTable}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const AwardEvidenceOfSelectivityForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm
      {...props} fileType={IndividualDocumentType.EvidenceOfSelectivity}
      subtitle={
        name
      }
      title={moduleName}
      cardTitle={<p>{name}</p>}
    />
  )
}

export const TaxReturnForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName, company } = module
  return (
    <OneIndividualDocumentForm {...props} fileType={IndividualDocumentType.TaxReturn}
      subtitle={
        name
      }
      cardTitle={<p>Please upload your tax return for <em>{company?.companyName}</em> showing your total earnings in that role, if available</p>}
      title={moduleName}
    />
  )
}

export const PayStubsForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName, company } = module
  return (
    <OneIndividualDocumentForm {...props} fileType={IndividualDocumentType.PayStubs}
      subtitle={
        name
      }
      cardTitle={<p>Please upload your final three consecutive pay stubs from your role at <em>{company?.companyName}</em></p>}
      title={moduleName}
    />
  )
}

export const PayBonusForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName, company } = module
  return (
    <OneIndividualDocumentForm {...props} fileType={IndividualDocumentType.PayBonus}
      subtitle={
        name
      }
      cardTitle={<p>Please provide any pay statements showing a large bonus you may have received at <em>{company?.companyName}</em></p>}
      title={moduleName}
    />
  )
}

export const EquityStatementForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name } = exhibit
  const { name: moduleName, company } = module
  return (
    <OneIndividualDocumentForm {...props} fileType={IndividualDocumentType.EquityStatement}
      subtitle={
        <>
          <p className="mb-3">If you received equity as part of your role, can you provide proof of your vested shares at the time of your employment at <b><em>{company?.companyName}</em></b> and proof of their value at that time?</p>
          <p>This could be a Carta Screenshot showing your granted equity shares and their valuation at <b><em>{company?.companyName}</em></b></p>
        </>
      }
      cardTitle={<p>Please provide a full page screenshot with URL link.</p>}
      title={moduleName}
    />
  )
}

export const EvidenceOfRegconitionForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm {...props} fileType={IndividualDocumentType.EvidenceOfRecognition}
      subtitle={
        name
      }
      cardTitle={<p>{name}</p>}
      title={moduleName}
    />
  )
}

export const PromotionDocumentForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm {...props} fileType={IndividualDocumentType.PromotionEvidence}
      subtitle={
        name
      }
      cardTitle={<p>Please provide any documentation showing promotions in this role</p>}
      title={moduleName}
    />
  )
}
export const PayRaiseEvidenceForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm {...props} fileType={IndividualDocumentType.PayRaiseEvidence}
      subtitle={
        name
      }
      cardTitle={<p>Please provide any documentation showing pay raise in this role</p>}
      title={moduleName}
    />
  )
}

export const CodeContributionDocumentsForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        <p>Code contributor page which clearly shows your name — the higher the code contributions, the better (the one with those cool graphs!)</p>
      }
      cardTitle={''}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.CodeContributorPage}
      footnote={FileFootnote}
    />
  )
}

export const CodeProjectOwnershipForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        <p>GitHub profile page and any pages showing your code project ownership — we want to see you’re an admin / and your pulls and commits</p>
      }
      cardTitle={''}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.CodeProjectOwnership}
      footnote={FileFootnote}
    />
  )
}

export const ConferencePresentationForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        <p>Conference presentations you've given at big-name events</p>
      }
      cardTitle={''}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.ConferencePresentation}
      footnote={FileFootnote}
    />
  )
}

export const InternalDocuments: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        CriticalRoleInternalDocuments
      }
      cardTitle={''}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.InternalDocs}
    />
  )
}

export const CustomerContractDocuments: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName, company } = module
  const subtitle = (
    <div>
      <p>Contracts you’ve signed on behalf of <b>{company?.companyName ?? 'the company'}</b></p>
    </div>
  )
  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        description ? description : subtitle
      }
      cardTitle={''}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.CustomerContract}
    />
  )
}

export const AcademicContractProposalDocuments: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        name
      }
      cardTitle={''}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.AcademicContractProposal}
    />
  )
}

export const AcademicPapersDocuments: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        name
      }
      cardTitle={''}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.AcademicPaper}
    />
  )
}

export const FundingDocuments: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        name
      }
      cardTitle={''}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.FundingDocument}
    />
  )
}

export const DistinguishedReputationDocuments: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName } = module
  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        CriticalRoleDistinguishedReputation
      }
      cardTitle={''}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.DistinguishedReputation}
      footnote={FileFootnote}
    />
  )
}

export const AwardEvidenceForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name, award } = exhibit as WorkflowAwardExhibit
  const { name: moduleName } = module

  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        name
      }
      cardTitle={''}
      title={`${moduleName}${award ? ` - ${award.awardName}` : ''}`}
      fileType={IndividualDocumentType.AwardEvidence}
    />
  )
}

export const EvidenceOfCommercialAdoptionForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name, award } = exhibit as WorkflowAwardExhibit
  const { name: moduleName } = module

  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        name
      }
      cardTitle={'Proof that your work has been commercially adopted or used in the real world'}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.EvidenceOfCommercialAdoption}
    />
  )
}

export const EvidenceOfSignificantUsageForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name, award } = exhibit as WorkflowAwardExhibit
  const { name: moduleName } = module

  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        name
      }
      cardTitle={'Proof that a large number of people are using your technology (think in the thousands, and tens of thousands, if not more!)'}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.EvidenceOfSignificantUsage}
    />
  )
}

export const EvidenceIndustryAwardsForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name, } = exhibit
  const { name: moduleName } = module

  return (
    <OneIndividualDocumentForm {...props}
      subtitle={
        name
      }
      cardTitle={'Proof that you received any industry awards or prizes.'}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.EvidenceOfIndustryAwards}
    />
  )
}
