import * as RadixCheckbox from "@radix-ui/react-checkbox";
import { CheckIcon } from "@radix-ui/react-icons";
import { cn } from "@utils/cn";
import { ControllerRenderProps, Path, FieldValues } from "react-hook-form";
import { FormLabel } from "./form";
import * as React from "react";

type CheckboxProps<T extends FieldValues> = {
  field: ControllerRenderProps<T, Path<T>>;
  label: string;
  className?: string;
};

export function Checkbox<T extends FieldValues>({ field, label, className }: CheckboxProps<T>) {
  const id = React.useId();
  return (
    <div className={cn("flex items-center space-x-2", className)}>
      <RadixCheckbox.Root
        id={id}
        checked={!!field.value}
        onCheckedChange={field.onChange}
        className={cn("flex h-4 w-4 appearance-none items-center justify-center rounded-[3px] border border-gray-400", field.value && "border-accent")}
      >
        <RadixCheckbox.Indicator className="text-accent">
          <CheckIcon className="w-4 h-4" />
        </RadixCheckbox.Indicator>
      </RadixCheckbox.Root>
      {label && <FormLabel htmlFor={id} className="text-primary">{label}</FormLabel>}
    </div>
  );
}
