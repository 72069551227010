import React from 'react';
import { useActorRef } from '@xstate/react';
import { useEmployerOnboarding } from '../../employer-onboarding';
import { beneficiaryFlowMachine } from '../machines/beneficiary-flow';
import { USRoleOfferForm } from './us-role-offer';
import { ImmigrationQuestionsForm } from './immigration-questions';
import { FormLoading } from '@pages/onboarding/beneficiary/forms/loading';
import { useUserEmploymentsQuery } from '@codegen/index';
import ErrorBoundary from '@components/error-boundary';

export const BeneficiaryFlow = () => {
  const { petitions, onSubmit, onBack, onSkip } = useEmployerOnboarding();
  const initialPetition = petitions?.[0]; // Get first petition for now
  
  const { data: userEmployments, loading: isLoadingEmployments } = useUserEmploymentsQuery({
    variables: {
      petitionerId: initialPetition?.companyByPetitionerId?.id ?? ''
    },
    skip: !initialPetition?.userByBeneficiaryId?.id || !initialPetition?.companyByPetitionerId?.id
  });

  const actor = useActorRef(beneficiaryFlowMachine, {
    input: {
      currentPetitionIndex: 0,
      petitions,
      userEmployments: userEmployments?.allUserEmployments?.nodes
    }
  });

  const [currentState, setCurrentState] = React.useState(actor.getSnapshot());
  const currentPetition = petitions[currentState.context.currentPetitionIndex];

  React.useEffect(() => {
    const subscription = actor.subscribe(setCurrentState);
    return subscription.unsubscribe;
  }, [actor]);

  if (isLoadingEmployments) {
    return <FormLoading />;
  }

  const handleSubmit = (data: any) => {
    actor.send({ type: 'SET_DATA', ...data });
    actor.send({ type: 'NEXT' });
    
    // If we're done with the flow, notify parent
    if (currentState.matches('immigrationQuestions') && 
        currentState.context.currentPetitionIndex === petitions.length - 1) {
      onSubmit({data, key: 'beneficiaryFlow'});
    }
  };

  const currentEmployment = userEmployments?.allUserEmployments?.nodes?.find((employment) => employment?.userId === currentPetition.userByBeneficiaryId?.id);

  if (isLoadingEmployments || !currentPetition) {
    return (
      <FormLoading />
    )
  }

  if (currentState.matches('usRoleOffer')) {
    return (
      <ErrorBoundary onSkip={onSkip} exitPath={'/onboarding/employer'} onBack={onBack}>
        <USRoleOfferForm 
          petition={currentPetition} 
          existingEmployment={currentEmployment}
          onSubmit={handleSubmit} 
          onBack={onBack} 
        />
      </ErrorBoundary>
    );
  }

  if (currentState.matches('immigrationQuestions')) {
    return (
      <ErrorBoundary onSkip={onSkip} exitPath={'/onboarding/employer'} onBack={onBack}>
        <ImmigrationQuestionsForm 
          petition={petitions[currentState.context.currentPetitionIndex]} 
          onSubmit={handleSubmit} 
          onBack={() => actor.send({type: 'BACK'})} 
        />
      </ErrorBoundary>
    );
  }

  return null;
};
