import { ApolloError } from '@apollo/client'
import * as Sentry from '@sentry/react'

const ENV = import.meta.env.VITE_NODE_ENV
export const useLogError = () => {
  return (error: any, extra?: any) => {
    if (ENV === 'development') {
      console.error(error, extra)
    } else {
      let message = '', formattedError
      if (error instanceof ApolloError) {
        message = error.message
        formattedError = error.graphQLErrors?.[0]
      } else {
        message = error
        formattedError = error
      }

      Sentry.captureException(formattedError, {
          ...{extra, env: ENV, message}
      })
    }
  }
}
