import { assign } from "xstate";

import { setup } from "xstate";

interface BeneficiaryFlowContext {
  currentPetitionIndex: number;
  petitions: any[]; // Replace 'any' with your actual petition type
}

type OnboardingEvent = 
  | { type: 'NEXT' }
  | { type: 'BACK' };

export const beneficiaryFlowMachine = setup({
  types: {
    context: {} as BeneficiaryFlowContext,
    events: {} as OnboardingEvent,
  },
}).createMachine({
  id: 'beneficiaryFlow',
  initial: 'usRoleOffer',
  context: {
    currentPetitionIndex: 0,
    petitions: []
  },
  states: {
    usRoleOffer: {
      on: {
        NEXT: 'immigrationQuestions',
        // BACK: 'final'  // Simply specify the state name
      },
    },
    immigrationQuestions: {
      on: {
        NEXT: [
          {
            actions: assign({
              currentPetitionIndex: ({ context }) => context.currentPetitionIndex + 1
            }),
            guard: ({ context }: { context: BeneficiaryFlowContext }) => context.currentPetitionIndex === context.petitions.length - 1,
            target: 'final'
          },
          {
            actions: assign({
              currentPetitionIndex: ({ context }) => context.currentPetitionIndex + 1
            }),
            target: 'usRoleOffer'
          }
        ],
        BACK: 'usRoleOffer',
      },
    },
    final: {
      type: 'final'
    }
  }
});
