import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@components/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from 'react'
import { z } from "zod";
import { Input } from "@components/input";
import { Button } from "@components/button";
import { useState } from "react";
import {
  useCompanyByIdQuery,
  useUpdateCompanyMutation,
} from "@codegen/index";
import { EmployerOnboardingContainer } from "@pages/onboarding/employer/forms/employer-onboarding-container";
import { CTAContainer } from "@pages/onboarding/shared/cta-container";
import { BusinessTypeSelect } from "@components/type-of-business-select";
import { FieldsContainer } from "@pages/onboarding/shared/fields-container";
import { useLogError } from "@utils/error";
import { WaitingSplash } from "@pages/onboarding/shared/waiting";
import { Company } from "@codegen/schema";
import { useEmployerOnboarding } from '../../employer-onboarding';

const employeeHistorySchema = z.object({
  companyTypeOfBusiness: z.string().min(1, { message: "Required" }),
  currentNumberOfEmployees: z.string().min(1, { message: "Required" }),
  companyGrossAnnualIncome: z.string().min(1, { message: "Required" }),
  companyNetAnnualIncome: z.string().min(1, { message: "Required" }),
});

type EmployerHistoryType = z.infer<typeof employeeHistorySchema>;

export const EmployerHistoryForm = (props: { companyData: Pick<Company, 'id' | 'companyTypeOfBusiness' | 'currentNumberOfEmployees' | 'companyGrossAnnualIncome' | 'companyNetAnnualIncome'> }) => {
  const { onSubmit, company, onBack } = useEmployerOnboarding();
  const [status, setStatus] = useState<"idle" | "loading" | "error">("idle");

  const [saveCompany] = useUpdateCompanyMutation()
  const form = useForm<EmployerHistoryType>({
    resolver: zodResolver(employeeHistorySchema),
    defaultValues: {
      companyTypeOfBusiness: props.companyData?.companyTypeOfBusiness ?? undefined,
      currentNumberOfEmployees: props.companyData?.currentNumberOfEmployees?.toString() ?? undefined,
      companyGrossAnnualIncome: props.companyData?.companyGrossAnnualIncome ?? undefined,
      companyNetAnnualIncome: props.companyData?.companyNetAnnualIncome ?? undefined,
    }
  });
  const logError = useLogError()
  const saveEmployerData = React.useCallback(async (data: EmployerHistoryType) => {
    try {
      if (company == null) return;
      setStatus("loading");
      const { errors } = await saveCompany({
        variables: {
          input: {
            id: company.value,
            // @ts-ignore
            companyPatch: {
              companyTypeOfBusiness: data.companyTypeOfBusiness,
              currentNumberOfEmployees: parseInt(data.currentNumberOfEmployees),
              companyGrossAnnualIncome: data.companyGrossAnnualIncome,
              companyNetAnnualIncome: data.companyNetAnnualIncome,
            },
          },
        },
      });

      if (errors != null) {
        throw errors
      }

      onSubmit({ data, key: 'employerHistory' });
    } catch (exception) {
      logError(exception)
      setStatus('error')
    }
  }, [setStatus, saveCompany, onSubmit, company])
  return (
    <EmployerOnboardingContainer
      title="Employer History"
      subtitle={
        <div>
          Please provide the following details about {company?.label}
        </div>
      }
      cardTitle={""}
      progress={
        0
      }
      form={form}
      onSubmit={saveEmployerData}
      name="employer_history"
    >
      <FieldsContainer status={status}>
        <FormField
          control={form.control}
          name="companyTypeOfBusiness"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel className="text-md">Type of Business</FormLabel>
              <BusinessTypeSelect
                field={field}
                onSelect={(x: string) => form.setValue("companyTypeOfBusiness", x)}
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="currentNumberOfEmployees"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-md">Current Number of Employees in the US</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  type="number"
                  className="h-11"
                  placeholder="Number of Employees"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="companyGrossAnnualIncome"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-md">Company's Gross Annual Income (in US dollars)</FormLabel>
              <FormControl>
                <Input type="number" {...field} className="h-11" placeholder="$" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="companyNetAnnualIncome"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-md">Company's Net Annual Income (in US dollars)</FormLabel>
              <FormControl>
                <Input type="number" {...field} className="h-11" placeholder="$" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </FieldsContainer>
      <CTAContainer onBack={onBack}>
        <Button
          variant="accent"
          className="ml-auto text-md rounded-sm px-7 py-5"
          disabled={false}
          type="submit"
        >
          Next
        </Button>
      </CTAContainer>
    </EmployerOnboardingContainer >
  );
};

export const EmployerHistoryFormController = () => {
  const { company } = useEmployerOnboarding()
  const { data, loading, error } = useCompanyByIdQuery({
    variables: {
      id: company?.value
    },
    skip: !company?.value
  })

  if (loading || error || !data || !data.companyById) {
    return <WaitingSplash />
  }

  return (
    <EmployerHistoryForm companyData={data.companyById} />
  )
}